.flip-card {
  position: relative;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  border-radius: 0.375rem;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -moz-backface-visibility: hidden;
}

.flip-card:hover .flip-card-inner {
  transform: rotateX(-180deg);
  
}

.flip-card .front,
.flip-card .back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 0.75rem; 
}

.flip-card .front {
  z-index: 1;
}

.flip-card .back {
  transform: rotateX(180deg);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
  background: linear-gradient(to bottom right, #3b82f6, #9333ea);
}

.group:hover .overlay {
  opacity: 0.9;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.content p {
  color: white;
}




